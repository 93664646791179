import { cva, type VariantProps } from 'class-variance-authority';
import { type BaseHTMLAttributes, forwardRef, type ReactNode } from 'react';

import { cn } from '@/utils';

const textVariants = cva('', {
  variants: {
    variant: {
      primary: 'text-content-primary',
      secondary: 'text-content-secondary',
      tertiary: 'text-content-tertiary',
      disabled: 'text-content-disabled',
      critical: 'text-content-critical',
      placeholder: 'text-content-placeholder',
      informative: 'text-content-informative',
      success: 'text-content-success',
      warning: 'text-content-warning',
      disabledOnSurface: 'text-content-disabledOnSurface',
      informativeOnSurface: 'text-content-informativeOnSurface',
      warningOnSurface: 'text-content-warningOnSurface',
      criticalOnSurface: 'text-content-criticalOnSurface',
      placeholderOnSurface: 'text-content-placeholderOnSurface',
      inversePrimary: 'text-content-inversePrimary',
      interactive: 'text-content-interactive',
      inherit: 'text-inherit',
    },
    size: {
      xl: 'text-xl', // 24px
      lg: 'text-lg', // 20px
      md: 'text-md', // 16px
      sm: 'text-sm', // 14px
      xs: 'text-xs', // 11px
    },
    weight: {
      bold: 'font-bold',
      medium: 'font-medium',
      semiBold: 'font-semibold',
      // @TODO: doesn't match Figma design system ("regular"). As tailwind uses `normal` everywhere, maybe we should use `normal` in Figma?
      // https://www.figma.com/file/JOswcsJhl8Tl6JbQm3T0PZ?node-id=4:16507&mode=dev#579836491
      normal: 'font-normal',
    },
    clamp: {
      '1': 'line-clamp-1',
      '2': 'line-clamp-2',
      '3': 'line-clamp-3',
      '4': 'line-clamp-4',
      '5': 'line-clamp-5',
      '6': 'line-clamp-6',
      none: 'line-clamp-none',
      ignore: '',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
    weight: 'normal',
    clamp: 'none',
  },
});

type Props = Pick<BaseHTMLAttributes<HTMLParagraphElement>, 'role'> &
  VariantProps<typeof textVariants> & {
    as?: 'p' | 'span';
    className?: string;
    children: ReactNode;
  };

export const Text = forwardRef<HTMLParagraphElement, Props>(
  (
    { as: Comp = 'p', size, variant, weight, clamp, className, ...props },
    ref,
  ) => (
    <Comp
      className={cn(textVariants({ variant, size, weight, clamp }), className)}
      ref={ref}
      {...props}
    />
  ),
);

Text.displayName = 'Text';
