import { type LucideProps } from 'lucide-react';
import { forwardRef } from 'react';

import { cn } from '@/utils';

export const EmberSoftwareWebclip = forwardRef<SVGSVGElement, LucideProps>(
  (props, ref) => (
    <svg
      width='256'
      height='256'
      viewBox='0 0 256 256'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
      className={cn('fill-white', props.className)}
    >
      <rect width='256' height='256' className='fill-brand' />
      <path
        d='M127.911 47.739C127.833 47.6604 127.702 47.6604 127.623 47.739L80.8719 94.4903C62.0001 113.362 56.8891 140.761 65.5124 164.272L127.903 101.882C142.852 86.9328 142.852 62.6966 127.903 47.7478L127.911 47.739Z'
        fill='white'
      />
      <path
        d='M189.995 118.421L104.819 203.597C128.339 212.255 155.781 207.144 174.662 188.263C193.551 169.374 198.653 141.94 189.995 118.421Z'
        fill='white'
      />
      <path
        d='M160.805 135.344C175.946 120.203 175.946 95.6606 160.805 80.5195L75.323 166.002C71.4612 169.863 70.9283 175.988 74.1522 180.4C76.1617 183.152 78.3983 185.773 80.8796 188.254C85.5713 192.946 90.796 196.79 96.3615 199.778L160.796 135.344H160.805Z'
        fill='white'
      />
    </svg>
  ),
);

EmberSoftwareWebclip.displayName = 'EmberSoftwareWebclip';
