import { type LucideProps } from 'lucide-react';
import { forwardRef } from 'react';

import { cn } from '@/utils';

export const EmberSoftware = forwardRef<SVGSVGElement, LucideProps>(
  (props, ref) => (
    <svg
      width='78'
      height='93'
      viewBox='0 0 78 93'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      className={cn('fill-brand', props.className)}
      {...props}
    >
      <path d='M38.6293 0.0342788C38.5836 -0.0114263 38.5074 -0.0114263 38.4617 0.0342788L11.2875 27.2085C0.318305 38.1777 -2.6525 54.1033 2.35982 67.7691L38.6242 31.5047C47.3132 22.8157 47.3132 8.72839 38.6242 0.0393621L38.6293 0.0342788Z' />
      <path d='M74.7154 41.1179L25.2067 90.6266C38.8776 95.6592 54.8286 92.6884 65.8029 81.7141C76.7823 70.7348 79.748 54.7888 74.7154 41.1179Z' />
      <path d='M57.7486 50.9545C66.5494 42.1537 66.5494 27.8887 57.7486 19.088L8.0622 68.7744C5.81757 71.019 5.50778 74.5789 7.38169 77.1435C8.5497 78.7431 9.84976 80.2666 11.292 81.7089C14.0191 84.436 17.0559 86.6704 20.2908 88.4072L57.7435 50.9545H57.7486Z' />
    </svg>
  ),
);

EmberSoftware.displayName = 'EmberSoftware';
