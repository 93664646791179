import { createBrowserRouter } from 'react-router-dom';

import { wrapCreateBrowserRouter } from '@sentry/react';

import { RouterErrorBoundary } from '@/components/error-boundaries/router-error-boundary';

import {
  pathToAuth,
  pathToAuthRedirect,
  pathToAuthTceCallback,
  pathToAuthQuickbooksCallback,
} from '@/configs/routes/auth';
import {
  pathToDebug,
  pathToDebugRestoreFromRxDBDump,
} from '@/configs/routes/debug';
import { pathToDownloadBucket } from '@/configs/routes/download-portal';
import { pathToDownload } from '@/configs/routes/downloads';
import { pathToPWAInstallInstructions } from '@/configs/routes/installation-instructions';
import {
  pathToInvoices,
  pathToNewInvoice,
  pathToInvoice,
  pathToInvoiceAppointment,
} from '@/configs/routes/invoices';
import {
  pathToOfficeAppointments,
  pathToOfficeAppointmentsEdit,
  pathToOfficeAppointmentsNew,
  pathToOfficeAppointmentsSuggested,
  pathToOfficeAppointmentReportEmailComposer,
  pathToOfficeCustomers,
  pathToOfficeCustomersEdit,
  pathToOfficeCustomersNew,
  pathToOfficeLocation,
  pathToOfficeLocationAppointments,
  pathToOfficeLocationAttachments,
  pathToOfficeLocationSystems,
  pathToOfficeLocationLinkToTce,
  pathToOfficeLocationReports,
  pathToOfficeLocationProposals,
  pathToOfficeLocationInvoices,
  pathToOfficeLocationLinkToQuickBooks,
  pathToOfficeLocations,
  pathToOfficeAppointmentReportPreview,
  pathToOfficeAppointmentReportEdit,
  pathToOfficeAppointmentReports,
  pathToOfficeAppointment,
  pathToOfficeCustomerLinkToQuickBooks,
  pathToOfficeCustomer,
  pathToOfficeCustomerReports,
  pathToOfficeCustomerAppointments,
  pathToOfficeCustomerLocations,
  pathToOfficeCustomerProposals,
  pathToOfficeCustomerInvoices,
} from '@/configs/routes/office';
import {
  pathToProposals,
  pathToEditProposal,
  pathToReviewProposal,
  pathToProposalTeaser,
  pathToNewProposal,
  pathToProposalEmailComposer,
  pathToPrintProposal,
} from '@/configs/routes/proposals';
import {
  pathToSettings,
  pathToSettingsBusinessInfo,
  pathToSettingsProposalTemplate,
  pathToSettingsIntegrations,
  pathToSettingsFormBuilder,
} from '@/configs/routes/settings';
import {
  pathToTechAppointment,
  pathToTechAppointmentNew,
  pathToTechAppointmentNewCustomer,
  pathToTechAppointmentEditCustomer,
  pathToTechAppointmentPreview,
  pathToTechAppointmentReports,
  pathToTechAppointmentReportsSelect,
  pathToTechAppointments,
  pathToTechReport,
  pathToTechReportDeficiencies,
  pathToTechReportFill,
  pathToTechnicianSection,
  pathToTechReportPreviewPrintout,
} from '@/configs/routes/technician';
import {
  pathToTechControlPanel,
  pathToTechControlPanelAssetsNew,
  pathToTechControlPanelAssetsSearch,
  pathToTechControlPanelNew,
  pathToTechControlPanelRoot,
  pathToTechFireExtsRoot,
  pathToTechFireExt,
  pathToTechFireExtNew,
  pathToTechReportFireExtSearch,
  pathToTechSmokeDampersRoot,
  pathToTechSmokeDamper,
  pathToTechSmokeDamperNew,
  pathToTechReportSmokeDamperSearch,
  pathToTechReportAsset,
  pathToTechReportAssets,
  pathToTechReportAssetsNew,
  pathToTechReportAssetsNewEntryMenu,
  pathToTechReportAssetsSearch,
  pathToTechReportValvesSearch,
  pathToTechValve,
  pathToTechValveNew,
  pathToTechValvesRoot,
  pathToTechZoneOrLoop,
  pathToTechZoneOrLoopAssetsNew,
  pathToTechZoneOrLoopFloors,
  pathToTechZoneOrLoopFloorsAssetsNew,
  pathToTechZoneOrLoopRoot,
  pathToTechZoneOrLoopSearch,
} from '@/configs/routes/technician-assets';

import { appProviders, landingProviders, publicProviders } from '../providers';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    lazy: landingProviders(),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        index: true,
        lazy: () => import('@/pages/landing-page'),
      },
    ],
  },
  {
    path: '/',
    lazy: publicProviders(),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: pathToPrintProposal.template,
        children: [
          {
            index: true,
            lazy: () =>
              import('@/features/proposals/pages/print-proposal-page'),
          },
        ],
      },
      {
        path: pathToDownloadBucket.template,
        children: [
          {
            index: true,
            lazy: () =>
              import('@/features/download-portal/pages/download-bucket-page'),
          },
        ],
      },
      {
        path: pathToPWAInstallInstructions.template,
        lazy: () =>
          import(
            '@/features/pwa-install-instructions/pwa-install-instructions-page'
          ),
      },
    ],
  },
  {
    path: '/',
    lazy: appProviders({}),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: pathToOfficeAppointments.template,
        lazy: () => import('@/features/appointments/pages/appointments-page'),
        children: [
          {
            path: pathToOfficeAppointmentsNew.template,
            lazy: () =>
              import('@/features/appointments/pages/new-appointment-page'),
          },
          {
            path: pathToOfficeAppointmentsEdit.template,
            lazy: () =>
              import('@/features/appointments/pages/edit-appointment-page'),
          },
        ],
      },
      {
        // Suggested appointments are not child of appointments because the page uses the same filters as the appointments page and changing the filters in one page should not affect the other
        path: pathToOfficeAppointmentsSuggested.template,
        lazy: () =>
          import('@/features/appointments/pages/suggested-appointment-page'),
      },
      {
        path: pathToOfficeCustomers.template,
        lazy: () =>
          import('@/features/crm/customers/pages/list-customers-page'),
        children: [
          {
            path: pathToOfficeCustomersNew.template,
            lazy: () =>
              import('@/features/crm/customers/pages/new-customer-page'),
          },
          {
            path: pathToOfficeCustomersEdit.template,
            lazy: () =>
              import('@/features/crm/customers/pages/edit-customer-page'),
          },
          {
            path: pathToOfficeCustomerLinkToQuickBooks.template,
            lazy: () =>
              import('@/features/invoices/pages/quickbooks-customer-link-page'),
          },
        ],
      },
      {
        path: pathToOfficeLocations.template,
        lazy: () =>
          import('@/features/crm/locations/pages/list-locations-page'),
        children: [
          {
            path: pathToOfficeLocationLinkToTce.template,
            lazy: () => import('@/features/tce/pages/link-page'),
          },
          {
            path: pathToOfficeLocationLinkToQuickBooks.template,
            lazy: () =>
              import('@/features/invoices/pages/quickbooks-location-link-page'),
          },
        ],
      },
      {
        path: pathToOfficeLocation.template,
        lazy: () => import('@/features/crm/locations/pages/location-page'),
        children: [
          {
            path: pathToOfficeLocationReports.template,
            lazy: () =>
              import('@/features/crm/locations/pages/location-reports-page'),
          },
          {
            path: pathToOfficeLocationAttachments.template,
            lazy: () =>
              import(
                '@/features/crm/locations/pages/location-attachments-page'
              ),
          },
          {
            path: pathToOfficeLocationAppointments.template,
            lazy: () =>
              import(
                '@/features/crm/locations/pages/location-appointments-page'
              ),
          },
          {
            path: pathToOfficeLocationSystems.template,
            lazy: () =>
              import('@/features/crm/locations/pages/location-systems-page'),
          },
          {
            path: pathToOfficeLocationProposals.template,
            lazy: () =>
              import('@/features/crm/locations/pages/location-proposals-page'),
          },
          {
            path: pathToOfficeLocationInvoices.template,
            lazy: () =>
              import('@/features/crm/locations/pages/location-invoices-page'),
          },
        ],
      },
      {
        path: pathToOfficeCustomer.template,
        lazy: () => import('@/features/crm/customers/pages/customer-page'),
        children: [
          {
            path: pathToOfficeCustomerReports.template,
            lazy: () =>
              import('@/features/crm/customers/pages/customer-reports-page'),
          },
          {
            path: pathToOfficeCustomerAppointments.template,
            lazy: () =>
              import(
                '@/features/crm/customers/pages/customer-appointments-page'
              ),
          },
          {
            path: pathToOfficeCustomerLocations.template,
            lazy: () =>
              import('@/features/crm/customers/pages/customer-locations-page'),
          },
          {
            path: pathToOfficeCustomerProposals.template,
            lazy: () =>
              import('@/features/crm/customers/pages/customer-proposals-page'),
          },
          {
            path: pathToOfficeCustomerInvoices.template,
            lazy: () =>
              import('@/features/crm/customers/pages/customer-invoices-page'),
          },
        ],
      },
    ],
  },
  {
    path: pathToOfficeAppointment.template,
    lazy: appProviders({ withRxDb: true }), // RxDB necessary for some deeper nested components reused between tech and office pages
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: pathToOfficeAppointmentReports.template,
        lazy: () =>
          import('@/features/appointments/pages/appointment-reports-page'),
        children: [
          {
            path: pathToOfficeAppointmentReportEdit.template,
            lazy: () =>
              import(
                '@/features/appointments/pages/appointment-report-edit-page'
              ),
          },
          {
            path: pathToOfficeAppointmentReportPreview.template,
            lazy: () =>
              import(
                '@/features/appointments/pages/appointment-report-preview-page'
              ),
          },
          {
            path: pathToOfficeAppointmentReportEmailComposer.template,
            lazy: () =>
              import(
                '@/features/appointments/pages/appointment-report-send-page'
              ),
          },
        ],
      },
    ],
  },
  {
    path: 'reports',
    lazy: appProviders({}),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        index: true,
        lazy: () => import('@/features/reports/pages/reports-page'),
      },
    ],
  },
  {
    path: pathToInvoices.template,
    lazy: appProviders({}),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        index: true,
        lazy: () => import('@/features/invoices/pages/invoices-page'),
      },
      {
        path: pathToNewInvoice.template,
        lazy: () => import('@/features/invoices/pages/new-invoice-page'),
      },
    ],
  },
  {
    path: pathToInvoice.template,
    lazy: appProviders({}),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: '',
        lazy: () => import('@/features/invoices/pages/invoice-page'),
        children: [
          {
            path: pathToInvoiceAppointment.template,
            lazy: () =>
              import('@/features/invoices/pages/invoice-appointment-page'),
          },
        ],
      },
    ],
  },
  {
    path: pathToProposals.template,
    lazy: appProviders({}),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: '',
        lazy: () => import('@/features/proposals/pages/proposals-page'),
        children: [
          {
            path: pathToNewProposal.template,
            lazy: () => import('@/features/proposals/pages/new-proposal-page'),
          },
          {
            path: pathToEditProposal.template,
            lazy: () => import('@/features/proposals/pages/edit-proposal-page'),
          },
          {
            path: pathToReviewProposal.template,
            lazy: () =>
              import('@/features/proposals/pages/review-proposal-page'),
          },
          {
            path: pathToProposalEmailComposer.template,
            lazy: () => import('@/features/proposals/pages/send-proposal-page'),
          },
        ],
      },
      {
        path: pathToProposalTeaser.template,
        lazy: () => import('@/features/proposals/pages/proposal-teaser-page'),
      },
    ],
  },
  {
    path: pathToTechnicianSection.template,
    lazy: appProviders({ withOfflineExchange: true, withRxDb: true }),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: pathToTechAppointments.template,
        // Appointments root component:
        //  - inits appointments collection
        //  - starts delayed synching process of active appointments and their data
        lazy: () =>
          import('@/features/appointments-for-tech/pages/appointments-root'),
        children: [
          {
            index: true,
            lazy: () =>
              import(
                '@/features/appointments-for-tech/pages/appointments-page'
              ),
          },
          {
            lazy: () =>
              import(
                '@/features/appointments-for-tech/pages/appointments-page'
              ),
            children: [
              {
                path: pathToTechAppointmentNew.template,
                lazy: () =>
                  import(
                    '@/features/appointments-for-tech/pages/new-appointment-page'
                  ),
              },
              {
                path: pathToTechAppointmentNewCustomer.template,
                lazy: () =>
                  import('@/features/crm/customers/pages/new-customer-page'),
              },
              {
                path: pathToTechAppointmentEditCustomer.template,
                lazy: () =>
                  import('@/features/crm/customers/pages/edit-customer-page'),
              },
            ],
          },
          {
            path: pathToTechAppointment.template,
            children: [
              {
                index: true,
                lazy: () =>
                  import(
                    '@/features/appointments-for-tech/pages/appointment-page'
                  ),
              },
              {
                path: pathToTechAppointmentPreview.template,
                lazy: () =>
                  import(
                    '@/features/appointments-for-tech/pages/preview-appointment-page'
                  ),
              },
              {
                path: pathToTechAppointmentReports.template,
                children: [
                  {
                    index: true,
                    // the same as appointment page but with reports tab pre-opened when there are other primary tabs (e.g. assets)
                    lazy: () =>
                      import(
                        '@/features/appointments-for-tech/pages/appointment-page'
                      ),
                  },
                  {
                    path: pathToTechAppointmentReportsSelect.template,
                    lazy: () =>
                      import(
                        '@/features/reports-for-tech/pages/select-reports-page'
                      ),
                  },
                  {
                    path: pathToTechReport.template,
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import(
                            '@/features/reports-for-tech/pages/report-summary-page'
                          ),
                      },
                      {
                        path: pathToTechReportFill.template,
                        lazy: () =>
                          import(
                            '@/features/forms-for-tech/pages/response-page'
                          ),
                      },
                      {
                        path: pathToTechReportDeficiencies.template,
                        lazy: () =>
                          import(
                            '@/features/reports-for-tech/pages/deficiencies-page'
                          ),
                      },
                      {
                        path: pathToTechReportPreviewPrintout.template,
                        lazy: () =>
                          import(
                            '@/features/reports-for-tech/pages/preview-printout-page'
                          ),
                      },
                      {
                        path: pathToTechReportAssets.template,
                        children: [
                          {
                            // report/:reportId/assets is the same as report/:reportId just to pre-select the assets tab in the future
                            index: true,
                            lazy: () =>
                              import(
                                '@/features/reports-for-tech/pages/report-summary-page'
                              ),
                          },
                          {
                            // selection page - new asset or new control panel
                            path: pathToTechReportAssetsNewEntryMenu.template,
                            lazy: () =>
                              import(
                                '@/features/assets-for-tech/pages/assets-new-entry-menu-page'
                              ),
                          },
                          {
                            // new asset page
                            path: pathToTechReportAssetsNew.template,
                            lazy: () =>
                              import(
                                '@/features/assets-for-tech/pages/assets-new-page'
                              ),
                          },
                          {
                            path: pathToTechReportAsset.template,
                            lazy: () =>
                              import(
                                '@/features/assets-for-tech/pages/asset-page'
                              ),
                          },
                          {
                            path: pathToTechReportAssetsSearch.template,
                            lazy: () =>
                              import(
                                '@/features/assets-for-tech/pages/assets-search-page'
                              ),
                          },
                          {
                            path: pathToTechControlPanelRoot.template,
                            children: [
                              {
                                path: pathToTechControlPanelNew.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/pages/control-panel-new-page'
                                  ),
                              },
                              {
                                path: pathToTechControlPanel.template,
                                children: [
                                  {
                                    index: true,
                                    lazy: () =>
                                      import(
                                        '@/features/assets-for-tech/pages/control-panel-page'
                                      ),
                                  },
                                  {
                                    path: pathToTechControlPanelAssetsNew.template,
                                    lazy: () =>
                                      import(
                                        '@/features/assets-for-tech/pages/assets-new-page'
                                      ),
                                  },
                                  {
                                    path: pathToTechControlPanelAssetsSearch.template,
                                    lazy: () =>
                                      import(
                                        '@/features/assets-for-tech/pages/control-panel-assets-search-page'
                                      ),
                                  },
                                  {
                                    path: pathToTechZoneOrLoopRoot.template,
                                    children: [
                                      {
                                        path: pathToTechZoneOrLoopAssetsNew.template,
                                        lazy: () =>
                                          import(
                                            '@/features/assets-for-tech/pages/assets-new-page'
                                          ),
                                      },
                                      {
                                        path: pathToTechZoneOrLoopFloors.template,
                                        lazy: () =>
                                          import(
                                            '@/features/assets-for-tech/pages/zone-loop-floors-page'
                                          ),
                                      },
                                      {
                                        path: pathToTechZoneOrLoopFloorsAssetsNew.template,
                                        lazy: () =>
                                          import(
                                            '@/features/assets-for-tech/pages/assets-new-page'
                                          ),
                                      },
                                      {
                                        path: pathToTechZoneOrLoop.template,
                                        lazy: () =>
                                          import(
                                            '@/features/assets-for-tech/pages/zone-loop-page'
                                          ),
                                      },
                                      {
                                        path: pathToTechZoneOrLoopSearch.template,
                                        lazy: () =>
                                          import(
                                            '@/features/assets-for-tech/pages/zone-loop-assets-search-page'
                                          ),
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            path: pathToTechValvesRoot.template,
                            children: [
                              {
                                path: pathToTechReportValvesSearch.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/pages/valves-search-page'
                                  ),
                              },
                              {
                                path: pathToTechValveNew.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/pages/valve-new-page'
                                  ),
                              },
                              {
                                path: pathToTechValve.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/pages/valve-page'
                                  ),
                              },
                            ],
                          },
                          {
                            path: pathToTechFireExtsRoot.template,
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/fire-exts/pages/fire-exts-page'
                                  ),
                              },
                              {
                                path: pathToTechReportFireExtSearch.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/fire-exts/pages/fire-exts-search-page'
                                  ),
                              },
                              {
                                path: pathToTechFireExtNew.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/fire-exts/pages/fire-ext-new-page'
                                  ),
                              },
                              {
                                path: pathToTechFireExt.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/fire-exts/pages/fire-ext-page'
                                  ),
                              },
                            ],
                          },
                          {
                            path: pathToTechSmokeDampersRoot.template,
                            children: [
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/smoke-dampers/pages/smoke-dampers-page'
                                  ),
                              },
                              {
                                path: pathToTechReportSmokeDamperSearch.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/smoke-dampers/pages/smoke-dampers-search-page'
                                  ),
                              },
                              {
                                path: pathToTechSmokeDamperNew.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/smoke-dampers/pages/smoke-damper-new-page'
                                  ),
                              },
                              {
                                path: pathToTechSmokeDamper.template,
                                lazy: () =>
                                  import(
                                    '@/features/assets-for-tech/smoke-dampers/pages/smoke-damper-page'
                                  ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: pathToSettings.template,
    lazy: appProviders({ withRxDb: true }),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: pathToSettingsBusinessInfo.template,
        lazy: () =>
          import('@/features/settings-business-info/pages/business-info-page'),
      },
      {
        path: pathToSettingsProposalTemplate.template,
        lazy: () =>
          import('@/features/proposal-templates/pages/proposal-template-page'),
      },
      {
        path: pathToSettingsIntegrations.template,
        lazy: () =>
          import('@/features/settings-integrations/pages/integrations-page'),
      },
      {
        path: pathToSettingsFormBuilder.template,
        lazy: () => import('@/features/form-builder/pages/form-builder-page'),
      },
    ],
  },
  {
    path: pathToDebug.template,
    lazy: appProviders({}),
    ErrorBoundary: RouterErrorBoundary,
    children: [
      {
        path: pathToDebugRestoreFromRxDBDump.template,
        lazy: () =>
          import('@/features/rxdb-debug-dump/pages/restore-from-rxdb-dump'),
      },
    ],
  },
  {
    lazy: appProviders({}), // this is needed as react-oidc needs auth provider to be present
    ErrorBoundary: RouterErrorBoundary,
    path: pathToAuth.template, // this URL prefix is used by react-oidc internally, added it to the router to avoid 404
    children: [
      {
        path: pathToAuthRedirect.template,
        element: null,
      },
      {
        path: pathToAuthTceCallback.template,
        lazy: () =>
          import('@/features/settings-integrations/pages/tce-redirect-page'),
      },
      {
        path: pathToAuthQuickbooksCallback.template,
        lazy: () =>
          import(
            '@/features/settings-integrations/pages/quickbooks-redirect-page'
          ),
      },
    ],
  },
  {
    path: pathToDownload.template,
    ErrorBoundary: RouterErrorBoundary,
    lazy: () => import('@/pages/download'),
  },
  {
    path: '*',
    ErrorBoundary: RouterErrorBoundary,
    lazy: () => import('@/pages/not-found'),
  },
]);
